import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IconService, SupportedFileTypeService } from '@isophi/core-ui';
import {
  AddressTypeEnum,
  AidPurchaseFormEnum,
  AttendanceEndCodeEnum,
  AttendanceFulfillmentMethodEnum,
  AttendanceStartCodeEnum,
  ChildGroupSuggestion,
  ChildProfileCreate,
  ClassesService,
  DisabilityTypeEnum,
  DisadvantagesEnum,
  EducationCourseChoiceEnum,
  Gender785Enum,
  InsuranceCompanyEnum,
  LevelOfTalentEnum,
  MeasureLevelEnum,
  NationalityEnum,
  NationalityQualifierEnum,
  SocialCategoryDisadvantageEnum,
  SupportingMeasures,
  SupportingMeasuresService,
} from '@isophi/mng-api';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, map, Observable, shareReplay, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { BirthNumberService } from '../../../../core/services/birth-number.service';
import { HelperService } from '../../../../core/services/helper.service';
import {
  attendanceEndCodeData,
  attendanceFulfillmentMethodData,
  attendanceStartCodeData,
  disadvantagesData,
  educationCourseChoiceData,
  insuranceCompanyData,
  measureLevelData,
  nationalityData,
  nationalityQualifierData,
  socialCategoryDisadvantageData,
} from './data';

@Component({
  selector: 'app-child-form',
  templateUrl: './child-form.component.html',
  styleUrls: ['./child-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildFormComponent implements OnInit, OnDestroy {
  @Input() submitButtonText: string;

  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();

  childForm: FormGroup;

  initialValues;

  profileImageUrl: string;

  groupList$: Observable<ChildGroupSuggestion[]>;

  setGroupList$: Observable<ChildGroupSuggestion[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  GenderEnum = Gender785Enum;

  nationalities: { [key: string]: string }[] = [];

  nationalitiesQualifier: { [key: string]: string }[] = [];

  insuranceCompany: { [key: string]: string }[] = [];

  attendanceStartCode: { [key: string]: string }[] = [];

  attendanceEndCode: { [key: string]: string }[] = [];

  attendanceFulfillmentMethod: { [key: string]: string }[] = [];

  educationCourseChoice: { [key: string]: string }[] = [];

  socialCategoryDisadvantage: { [key: string]: string }[] = [];

  disadvantages: { [key: string]: string }[] = [];

  measureLevel: { [key: string]: string }[] = [];

  showWarning = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AddressType = AddressTypeEnum;

  showTemporaryAddress = true;

  showContactAddress = true;

  disabilityType: DisabilityTypeEnum[] = [];

  aidPurchaseForm: AidPurchaseFormEnum[] = [];

  levelOfTalent: LevelOfTalentEnum[] = [];

  warningFields: string[] = [];

  addressWarningFields: string[] = [];

  supportingMeasuresList$: Observable<SupportingMeasures[]>;

  get minDate(): NgbDateStruct {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear() - 30,
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
  }

  get maxDate(): NgbDateStruct {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
  }

  private _subs: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    public links: RouterLinkFactory,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    public icons: IconService,
    private classesService: ClassesService,
    private activatedRoute: ActivatedRoute,
    private birthNumberService: BirthNumberService,
    public supportedFileTypeService: SupportedFileTypeService,
    private supportingMeasuresService: SupportingMeasuresService
  ) {
    this.groupList$ = this.classesService.classesSuggestionsList().pipe(shareReplay(1));
    this.setGroupList$ = this.groupList$.pipe(map((data) => JSON.parse(JSON.stringify(data))));
    this.supportingMeasuresList$ = this.supportingMeasuresService.supportingMeasuresList(1000).pipe(map((val) => val.results));
    this.nationalities = this.helperService.prepareData(nationalityData, NationalityEnum);
    this.nationalitiesQualifier = this.helperService.prepareData(nationalityQualifierData, NationalityQualifierEnum);
    this.insuranceCompany = this.helperService.prepareData(insuranceCompanyData, InsuranceCompanyEnum);
    this.attendanceStartCode = this.helperService.prepareData(attendanceStartCodeData, AttendanceStartCodeEnum);
    this.attendanceEndCode = this.helperService.prepareData(attendanceEndCodeData, AttendanceEndCodeEnum);
    this.attendanceFulfillmentMethod = this.helperService.prepareData(attendanceFulfillmentMethodData, AttendanceFulfillmentMethodEnum);
    this.educationCourseChoice = this.helperService.prepareData(educationCourseChoiceData, EducationCourseChoiceEnum);
    this.socialCategoryDisadvantage = this.helperService.prepareData(socialCategoryDisadvantageData, SocialCategoryDisadvantageEnum);
    this.disadvantages = this.helperService.prepareData(disadvantagesData, DisadvantagesEnum);
    this.measureLevel = this.helperService.prepareData(measureLevelData, MeasureLevelEnum);
    this.disabilityType = Object.values(DisabilityTypeEnum);
    this.aidPurchaseForm = Object.values(AidPurchaseFormEnum);
    this.levelOfTalent = Object.values(LevelOfTalentEnum);

    this.childForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      nickname: [''],
      profileImage: [''],
      idCode: [''],
      primaryClass: [null],
      setGroups: [null],
      nationality: [this.nationalities[59].value],
      birthNumber: [{ value: '', disabled: false }, this.birthNumberValidator],
      birthPlace: [''],
      birthDate: [null, Validators.required],
      nationalityQualifier: [this.nationalitiesQualifier[1].value],
      gender: ['', Validators.required],
      insuranceCompany: [null],
      gdprConsent: [false],
      importantNote: [''],
      attendanceStart: [null],
      attendanceEnd: [null],
      attendanceStartCode: [null],
      attendanceEndCode: [null],
      attendanceFulfillmentMethod: [null],
      educationCourseChoice: [null],
      socialCategoryDisadvantage: [this.socialCategoryDisadvantage[0].value],
      isHealthDisadvantaged: [false],
      disadvantages: [[this.disadvantages[0].value], this.validateDisadvantages],
      measureLevel: [null],
      ignoranceOfLanguage: [false],
      levelOfTalent: [this.levelOfTalent[0]],
      disabilityType: [null],
      hasHeavyDisability: [false],
      izoSpz: [''],
      spzRecommendationIssued: [null],
      spzRecommendationDue: [null],
      supportingMeasures: [null],
      aidPurchaseForm: [null],
      isFundingRequired: [false],
      supportingMeasuresStart: [null],
      supportingMeasuresEnd: [null],
      addresses: this.formBuilder.array([
        this.formBuilder.group({
          addressType: [AddressTypeEnum.Permanent],
          catchment: [false],
          streetAndHouseNumber: [''],
          city: [''],
          country: [this.nationalities[59].value],
          district: [''],
          postalCode: [''],
        }),
      ]),
    });

    // For resetting form;
    this.initialValues = { ...this.childForm.value };

    this.warningFields = [
      'firstName',
      'lastName',
      'birthNumber',
      'birthDate',
      'gender',
      'nationalityQualifier',
      'attendanceStart',
      'socialCategoryDisadvantage',
      'disadvantages',
      'levelOfTalent',
    ];

    this.addressWarningFields = ['city', 'district', 'country'];

    this._subs.push(
      this.childForm.valueChanges.subscribe(() => {
        this.showWarning = this.checkFieldsEmpty(this.warningFields, this.addresses, this.addressWarningFields);
      }),
      this.activatedRoute.queryParams.subscribe((val) => {
        if (val.groupUuid) {
          this.childForm.patchValue({ primaryClass: val.groupUuid });
        }
      })
    );
  }

  get addresses(): FormArray {
    return this.childForm.get('addresses') as FormArray;
  }

  ngOnInit(): void {
    this._subs.push(
      this.childForm.controls.birthNumber.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
        const birthNumberControl = this.childForm.get('birthNumber');

        if (birthNumberControl.valid && !this.childForm.get('birthDate').value) {
          const newBirthDate = this.birthNumberService.convertBirthNumber(value);
          if (this.childForm.controls.birthDate.value !== newBirthDate) {
            this.childForm.controls.birthDate.setValue(newBirthDate, { emitEvent: false });
            this.cd.markForCheck();
          }
        }
      })
    );
  }

  addAddress(addressType: AddressTypeEnum) {
    const addressFormGroup = this.formBuilder.group({
      addressType: [addressType],
      catchment: [false],
      streetAndHouseNumber: [''],
      city: [''],
      country: [null],
      district: [''],
      postalCode: [''],
    });
    this.addresses.push(addressFormGroup);
  }

  deleteAddress(addressType: AddressTypeEnum) {
    for (let i = 0; i < this.addresses.length; i++) {
      const addressFormGroup = this.addresses.at(i) as FormGroup;
      const addressTypeControl = addressFormGroup.get('addressType');

      if (addressTypeControl.value === addressType) {
        this.addresses.removeAt(i);
        break;
      }
    }
  }

  handleAddresses(addressType: AddressTypeEnum, show: boolean) {
    if (show) {
      this.addAddress(addressType);
    } else {
      this.deleteAddress(addressType);
    }

    if (addressType === this.AddressType.Contact) {
      this.showContactAddress = !this.showContactAddress;
    }
    if (addressType === this.AddressType.Temporary) {
      this.showTemporaryAddress = !this.showTemporaryAddress;
    }
  }

  private birthNumberValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value) {
      const checkValue = /^\d{6}\/\d{4}$/.test(value);

      if (!checkValue) {
        return { invalidBirthNumber: true };
      }
    }
    return null;
  }

  private validateDisadvantages(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value.length > 1 && value.includes(DisadvantagesEnum.WithoutDisadvantage)) {
      return { invalidDisadvantages: true };
    }
    return null;
  }

  onFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.profileImageUrl = reader.result as string;
        this.cd.markForCheck();
      };

      reader.onloadstart = () => {
        this.spinner.show('preview');
      };

      reader.onloadend = () => {
        this.spinner.hide('preview');
      };

      reader.readAsDataURL(file);
      this.childForm.patchValue({ profileImage: file });
    }
  }

  onSelectedNationalityChanges(nationality) {
    if (nationality?.value === NationalityEnum.CZ) {
      this.childForm.get('birthNumber').enable();
      if (!this.warningFields.includes('birthNumber')) {
        this.warningFields.push('birthNumber');
        this.showWarning = this.checkFieldsEmpty(this.warningFields, this.addresses, this.addressWarningFields);
        this.cd.markForCheck();
      }
    } else {
      this.childForm.get('birthNumber').disable();
      if (this.warningFields.includes('birthNumber')) {
        this.warningFields.splice(this.warningFields.indexOf('birthNumber'), 1);
        this.showWarning = this.checkFieldsEmpty(this.warningFields, this.addresses, this.addressWarningFields);
        this.cd.markForCheck();
      }
    }
  }

  onbirthNumberChange(event) {
    if (this.childForm.get('birthNumber').valid && !this.childForm.get('gender').value) {
      const months = +event.target.value.substring(2, 4);

      if (months > 50) {
        this.childForm.patchValue({ gender: this.GenderEnum.Female });
      } else {
        this.childForm.patchValue({ gender: this.GenderEnum.Male });
      }
    }
  }

  onPrimaryClassChange(e) {
    if (e) {
      this.setGroupList$ = this.setGroupList$.pipe(map((groups) => groups.filter((group) => group.uuid !== e.uuid)));
      if (this.childForm.value.setGroups && this.childForm.value.setGroups.includes(e.uuid)) {
        this.childForm.patchValue({
          setGroups: [...this.childForm.value.setGroups.filter((groupUuid) => groupUuid !== e.uuid)],
        });
      }
    } else {
      this.setGroupList$ = this.groupList$.pipe(map((data) => JSON.parse(JSON.stringify(data))));
    }
  }

  private checkFieldsEmpty(fieldNames: string[], addresses: FormArray, addressFieldNames: string[]): boolean {
    for (const fieldName of fieldNames) {
      const control = this.childForm.get(fieldName);

      if ((control && !control.value) || control.value?.length === 0) {
        return true;
      }
    }

    for (const address of addresses.controls) {
      const addressFormGroup = address as FormGroup;

      if (addressFormGroup.get('addressType').value === this.AddressType.Permanent) {
        for (const fieldName of addressFieldNames) {
          const control = addressFormGroup.get(fieldName);

          if (control && !control.value && control) {
            return true;
          }
        }
      }
    }

    return false;
  }

  onSubmit(): void {
    if (this.childForm.valid) {
      const formValue = this.childForm.value;
      const childData = this.formatFormSubmitData(formValue);
      const profileImage = formValue.profileImage;

      if (!childData.setGroups?.length) {
        childData.setGroups = [];
      }

      if (childData.primaryClass) {
        childData.setGroups?.unshift(childData.primaryClass);
      }

      this.formSubmit.emit({ data: childData, file: profileImage });
    }
  }

  private formatFormSubmitData(formValue): ChildProfileCreate {
    return {
      firstName: formValue?.firstName,
      lastName: formValue.lastName,
      nickname: formValue.nickname,
      birthDate: this.helperService.formateDate(formValue.birthDate),
      gender: formValue.gender,
      birthNumber: formValue.birthNumber,
      birthPlace: formValue.birthPlace,
      idCode: formValue.idCode,
      nationality: formValue.nationality,
      nationalityQualifier: formValue.nationalityQualifier,
      gdprConsent: formValue.gdprConsent,
      primaryClass: formValue.primaryClass,
      setGroups: formValue.setGroups,
      attendanceStart: this.helperService.formateDate(formValue.attendanceStart),
      attendanceEnd: this.helperService.formateDate(formValue.attendanceEnd),
      attendanceStartCode: formValue.attendanceStartCode,
      attendanceEndCode: formValue.attendanceEndCode,
      attendanceFulfillmentMethod: formValue.attendanceFulfillmentMethod,
      disadvantages: formValue.disadvantages,
      educationCourseChoice: formValue.educationCourseChoice,
      ignoranceOfLanguage: formValue.ignoranceOfLanguage,
      importantNote: formValue.importantNote,
      insuranceCompany: formValue.insuranceCompany,
      isHealthDisadvantaged: formValue.isHealthDisadvantaged,
      measureLevel: formValue.measureLevel,
      socialCategoryDisadvantage: formValue.socialCategoryDisadvantage,
      levelOfTalent: formValue.levelOfTalent,
      disabilityType: formValue.disabilityType,
      hasHeavyDisability: formValue.hasHeavyDisability,
      izoSpz: formValue.izoSpz,
      spzRecommendationIssued: this.helperService.formateDate(formValue.spzRecommendationIssued),
      spzRecommendationDue: this.helperService.formateDate(formValue.spzRecommendationDue),
      supportingMeasures: +formValue.supportingMeasures || null,
      aidPurchaseForm: formValue.aidPurchaseForm,
      isFundingRequired: formValue.isFundingRequired,
      supportingMeasuresStart: this.helperService.formateDate(formValue.supportingMeasuresStart),
      supportingMeasuresEnd: this.helperService.formateDate(formValue.supportingMeasuresEnd),
      addresses: formValue.addresses,
    };
  }

  // For edit child
  setFormData(data): void {
    const { addresses, ...dataToEdit } = data;

    if (dataToEdit.nationality !== NationalityEnum.CZ) {
      this.childForm.get('birthNumber').disable();
      this.warningFields.splice(this.warningFields.indexOf('birthNumber'), 1);
      this.showWarning = this.checkFieldsEmpty(this.warningFields, this.addresses, this.addressWarningFields);
    }

    this.profileImageUrl = dataToEdit.profileImage;
    dataToEdit.birthDate = this.helperService.parseDate(dataToEdit.birthDate);
    dataToEdit.attendanceStart = this.helperService.parseDate(dataToEdit.attendanceStart);
    dataToEdit.attendanceEnd = this.helperService.parseDate(dataToEdit.attendanceEnd);
    dataToEdit.spzRecommendationIssued = this.helperService.parseDate(dataToEdit.spzRecommendationIssued);
    dataToEdit.spzRecommendationDue = this.helperService.parseDate(dataToEdit.spzRecommendationDue);
    dataToEdit.supportingMeasuresStart = this.helperService.parseDate(dataToEdit.supportingMeasuresStart);
    dataToEdit.supportingMeasuresEnd = this.helperService.parseDate(dataToEdit.supportingMeasuresEnd);
    dataToEdit.setGroups = dataToEdit.childGroups.map((group) => group.uuid).filter((groupUuid) => groupUuid !== dataToEdit.primaryClass);

    if (dataToEdit.primaryClass) {
      this.setGroupList$ = this.setGroupList$.pipe(map((groups) => groups.filter((group) => group.uuid !== dataToEdit.primaryClass)));
    }

    const permanentAddress = addresses.find((address) => address.addressType === AddressTypeEnum.Permanent);
    const otherAddress = addresses.filter((address) => address.addressType !== AddressTypeEnum.Permanent);

    otherAddress.forEach((addressData) => {
      this.handleAddresses(addressData.addressType, true);
    });
    this.addresses.patchValue([permanentAddress, ...otherAddress]);

    this.childForm.patchValue(dataToEdit);
  }

  resetChildForm(): void {
    this.childForm.reset(this.initialValues);
    this.profileImageUrl = '';
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
