<ng-container *transloco="let t">
  <label [for]="formControlName"
    >{{ label }}<ng-container *ngIf="required"><span [class.required-color]="requiredColor">*</span></ng-container
    ><ng-container *ngIf="parentVisible"
      >&nbsp;<i [class]="iconService.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i
    ></ng-container>

    <ng-container *ngIf="tooltipVisible"
      >&nbsp;<i [class]="iconService.infoCircle + ' tooltip-icon'" placement="top" [ngbTooltip]="tooltipText"></i
    ></ng-container>
  </label>
  <input
    [id]="formControlName"
    [formControl]="getFormControl()"
    class="form-control form-control-lg"
    [placeholder]="placeholder && placeholder"
    [ngClass]="{
      'is-invalid':
        (required || customValidation) &&
        childForm.get(formControlName).invalid &&
        (childForm.get(formControlName).dirty || childForm.get(formControlName).touched)
    }"
    type="text"
    (input)="onInputChange($event)"
  />
  <ng-container *ngIf="required || customValidation">
    <div
      *ngIf="childForm.get(formControlName).invalid && (childForm.get(formControlName).dirty || childForm.get(formControlName).touched)"
      class="invalid-feedback"
    >
      <div *ngIf="required && childForm.get(formControlName).hasError('required')">{{ formControlError }}</div>
      <div *ngIf="customValidation && childForm.get(formControlName).hasError(customErrorName)">
        {{ formControlCustomError }}
      </div>
    </div>
  </ng-container>
</ng-container>
