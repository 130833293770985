import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Kindergarten } from '@isophi/core-legacy';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KindergartenService {
  private readonly basePath = `${environment.kindergartenAPI}`;

  private readonly httpClient = inject(HttpClient);

  getEnrollmentState(): Observable<Kindergarten> {
    const url = `${this.basePath}/logged-kindergarten/?extra_fields=e_enrollment_state`;
    return this.httpClient.get<Kindergarten>(url);
  }
}
