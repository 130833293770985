import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './modules/layout/layout.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardRoutingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'children',
        loadChildren: () => import('./modules/children/children.module').then((m) => m.ChildrenModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'groups',
        loadChildren: () => import('./modules/groups/groups.module').then((m) => m.GroupsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'attendance',
        loadChildren: () => import('./modules/evidence/evidence.module').then((m) => m.EvidenceModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'gradebook',
        loadChildren: () => import('./modules/gradebook/gradebook.module').then((m) => m.GradebookModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'announcement',
        loadChildren: () => import('./modules/announcement/announcement.module').then((m) => m.AnnouncementModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production && false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
