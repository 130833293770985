<div class="close-relative-form border-r-16 no-gutters" *transloco="let t">
  <form [formGroup]="form" (ngSubmit)="createOrUpdateCloseRelative()">
    <div class="form-row">
      <app-text-input
        [childForm]="form"
        formControlName="firstName"
        [label]="t('child/firstName')"
        [required]="true"
        [formControlError]="t('child/firstNameError')"
      ></app-text-input>
      <app-text-input
        [childForm]="form"
        formControlName="lastName"
        [label]="t('child/lastName')"
        [required]="true"
        [formControlError]="t('child/lastNameError')"
      ></app-text-input>
    </div>
    <div class="form-row">
      <div class="form-group">
        <label for="relativeType">{{ t('closeRelative/relativeType') }}*</label>
        <ng-select
          id="relativeType"
          formControlName="relativeType"
          [items]="closeRelativeType"
          bindLabel="text"
          bindValue="value"
          [placeholder]="t('child/select')"
          class="form-control"
          appendTo="body"
        >
        </ng-select>
      </div>

      <div class="form-group">
        <label for="status">{{ t('closeRelative/status') }}*</label>
        <ng-select
          id="status"
          formControlName="status"
          [items]="closeRelativeStatusData"
          bindLabel="text"
          bindValue="value"
          [placeholder]="t('child/select')"
          class="form-control"
          appendTo="body"
        >
        </ng-select>
      </div>
    </div>

    <hr />

    <ng-container *ngIf="form.controls.status.value">
      <div class="form-row date">
        <div class="form-group">
          <app-date-input
            [childForm]="form"
            formControlName="birthDate"
            [label]="t('child/birthDate')"
            [placeholder]="t('child/selectBirthDate')"
            [required]="false"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [formControlError]="t('child/birthDateError')"
          ></app-date-input>
        </div>
        <div class="form-group"></div>
      </div>

      <div class="form-row">
        <app-text-input [childForm]="form" formControlName="phone" [label]="t('closeRelative/phone')"></app-text-input>
        <app-text-input [childForm]="form" formControlName="email" [label]="t('closeRelative/email')"></app-text-input>
      </div>

      <div class="form-row">
        <div class="form-group">
          <app-text-input [childForm]="form" formControlName="dataBox" [label]="t('closeRelative/dataBox')"></app-text-input>
        </div>
        <div class="form-group check d-flex">
          <input class="form-check-input" type="checkbox" id="isPrimary" formControlName="isPrimary" />
          <label class="form-check-label" for="isPrimary">{{ t('closeRelative/mainContactPerson') }}</label>
        </div>
      </div>

      <hr />

      <div formArrayName="addresses">
        <div class="row no-gutters" *ngFor="let address of addresses.controls; let i = index" [formGroupName]="i">
          <div class="col-12">
            <h2 *ngIf="address.value.addressType === AddressType.Permanent">{{ t('child/permanentAddress') }}</h2>
            <h2 class="mt-6" *ngIf="address.value.addressType === AddressType.Temporary">{{ t('child/temporaryAddress') }}</h2>
            <h2 class="mt-6" *ngIf="address.value.addressType === AddressType.Contact">{{ t('child/contactAddress') }}</h2>
          </div>
          <div *ngIf="address.value.addressType === AddressType.Permanent" class="form-group col-12">
            <div class="d-flex margin-b-5">
              <div class="form-group check d-flex mt-0">
                <input class="form-check-input" type="checkbox" id="sameAsChild" formControlName="sameAsChild" />
                <label class="form-check-label" for="sameAsChild">{{ t('closeRelative/sameAddress') }}</label>
              </div>
            </div>
          </div>
          <div class="col-md col-12">
            <div class="row no-gutters">
              <div class="form-group col-12">
                <label for="streetAndHouseNumber">
                  {{ t('child/streetAndHouseNumber')
                  }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent"></ng-container>
                </label>
                <input id="streetAndHouseNumber" formControlName="streetAndHouseNumber" class="form-control form-control-lg" type="text" />
              </div>
              <div *ngIf="address.value.addressType === AddressType.Permanent" class="form-group col-12">
                <label for="district"
                  >{{ t('child/district') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent"></ng-container
                ></label>
                <input id="district" formControlName="district" class="form-control form-control-lg" type="text" />
              </div>
              <div class="form-group col-12">
                <label for="country"
                  >{{ t('child/country') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent"></ng-container
                ></label>
                <ng-select
                  id="country"
                  formControlName="country"
                  [items]="nationalities"
                  bindLabel="text"
                  bindValue="value"
                  [placeholder]="t('child/selectCountry')"
                  class="form-control"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md col-12 mt-md-0 mt-4">
            <div class="row no-gutters">
              <div class="form-group col-12">
                <label for="city"
                  >{{ t('child/city') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent"></ng-container
                ></label>
                <input id="city" formControlName="city" class="form-control form-control-lg" type="text" />
              </div>
              <div class="form-group col-12">
                <label for="postalCode"
                  >{{ t('child/postalCode') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent"></ng-container
                ></label>
                <input id="postalCode" formControlName="postalCode" class="form-control form-control-lg" type="text" />
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-12 mt-6">
            <div class="row no-gutters">
              <div class="form-group col d-flex flex-md-row flex-column">
                <button
                  type="button"
                  (click)="handleAddresses(AddressType.Temporary, showTemporaryAddress)"
                  class="btn btn-outline-primary btn-icon me-md-4 me-0 mb-md-0 mb-4"
                >
                  <i [class]="showTemporaryAddress ? icons.plus : icons.minus"></i>
                  {{ t('child/addIntermediateAddress', { type: showTemporaryAddress ? t('child/add') : t('child/remove') }) }}
                </button>

                <button
                  type="button"
                  (click)="handleAddresses(AddressType.Contact, showContactAddress)"
                  class="btn btn-outline-primary btn-icon"
                  [disabled]="disabledAddContactAddress"
                >
                  <i [class]="showContactAddress ? icons.plus : icons.minus"></i>
                  {{ t('child/addContactAddress', { type: showContactAddress ? t('child/add') : t('child/remove') }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <h2>{{ t('closeRelative/bankAccount') }}</h2>
      </div>
      <div class="form-row">
        <app-text-input [childForm]="form" formControlName="bankName" [label]="t('closeRelative/bankName')"></app-text-input>
        <app-text-input
          [childForm]="form"
          formControlName="bankAccountNumber"
          [label]="t('closeRelative/bankAccountNumber')"
        ></app-text-input>
      </div>

      <div class="form-row">
        <app-text-input [childForm]="form" formControlName="iban" [label]="t('closeRelative/iban')"></app-text-input>
        <app-text-input [childForm]="form" formControlName="swift" [label]="t('closeRelative/swift')"></app-text-input>
      </div>

      <hr />

      <div class="form-row">
        <app-text-input [childForm]="form" formControlName="note" [label]="t('closeRelative/note')"></app-text-input>
      </div>
    </ng-container>
  </form>

  <div class="submit-btn row no-gutters">
    <div class="col text-center">
      <button (click)="navigateBack()" class="btn btn-lg btn-outline-primary me-4">
        {{ t('child/cancel') }}
      </button>

      <div class="dropdown">
        <div *ngIf="simplePrimaryBtn">
          <button
            class="btn btn-lg btn-primary primary-action"
            type="button"
            (click)="createOrUpdateCloseRelative(true)"
            [disabled]="form.invalid"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <div class="btn-group" *ngIf="!simplePrimaryBtn">
          <button
            class="btn btn-lg btn-primary primary-action"
            type="button"
            (click)="createOrUpdateCloseRelative(true)"
            [disabled]="form.invalid"
          >
            {{ submitButtonText }}
          </button>
          <button
            class="btn btn-lg btn-primary dropdown-toggle dropdown-toggle-split"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [disabled]="form.invalid"
          ></button>
          <div class="dropdown-menu dropdown-notification">
            <a (click)="createOrUpdateCloseRelative()" class="dropdown-item">{{ t('closeRelative/addAnother') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
