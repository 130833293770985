<div class="child-form border-r-16 no-gutters" *transloco="let t">
  <form class="col mt-4" [formGroup]="childForm" (ngSubmit)="onSubmit()">
    <div class="row no-gutters">
      <div class="col-md col-12">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <app-text-input
              [childForm]="childForm"
              formControlName="firstName"
              [label]="t('child/firstName')"
              [required]="true"
              [requiredColor]="true"
              [parentVisible]="true"
              [formControlError]="t('child/firstNameError')"
            ></app-text-input>
          </div>
          <div class="form-group col-12">
            <app-text-input
              [childForm]="childForm"
              formControlName="lastName"
              [label]="t('child/lastName')"
              [required]="true"
              [requiredColor]="true"
              [parentVisible]="true"
              [formControlError]="t('child/lastNameError')"
            ></app-text-input>
          </div>
          <div class="form-group col-12">
            <app-text-input
              [childForm]="childForm"
              formControlName="nickname"
              [label]="t('child/nickName')"
              [parentVisible]="true"
            ></app-text-input>
          </div>
        </div>
      </div>
      <div class="col-md col-12 mt-md-0 mt-4">
        <div class="row no-gutters h-100">
          <div class="form-group col-12 text-center profile-image">
            <label for="profileImage"
              >{{ t('child/profileImagePreview') }} <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i
            ></label>
            <div class="preview">
              <img *ngIf="profileImageUrl" [src]="profileImageUrl" alt="Preview" />
              <ngx-spinner name="primary" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-pulse" name="preview">
                {{ t('shared/loading') }}
              </ngx-spinner>
            </div>
            <input
              id="profileImage"
              class="form-control d-none"
              type="file"
              #fileInput
              (change)="onFileChange($event)"
              [accept]="supportedFileTypeService.image"
            />
            <button type="button" (click)="fileInput.click()" class="btn btn-outline-primary btn-icon">
              <i [class]="icons.plus"></i>
              {{ t('child/uploadPhoto') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row no-gutters">
      <div class="col-12">
        <h2>{{ t('child/basicData') }}</h2>
      </div>
      <div class="col-md col-12">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <label for="primaryClass">{{ t('child/class') }}*</label>
            <ng-select
              id="primaryClass"
              formControlName="primaryClass"
              [items]="groupList$ | async"
              bindLabel="name"
              bindValue="uuid"
              [placeholder]="t('child/selectClass')"
              class="form-control"
              (change)="onPrimaryClassChange($event)"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label for="setGroups">{{ t('child/otherGroups') }}</label>
            <ng-select
              id="setGroups"
              formControlName="setGroups"
              [items]="setGroupList$ | async"
              bindLabel="name"
              bindValue="uuid"
              [placeholder]="t('child/selectClasses')"
              class="form-control"
              [clearable]="true"
              [multiple]="true"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label for="nationality"
              >{{ t('child/nationality') }}* <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i
            ></label>
            <ng-select
              id="nationality"
              formControlName="nationality"
              [items]="nationalities"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectCountry')"
              class="form-control"
              (change)="onSelectedNationalityChanges($event)"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <app-text-input
              [childForm]="childForm"
              formControlName="birthPlace"
              [label]="t('child/birthPlace')"
              [parentVisible]="true"
            ></app-text-input>
          </div>
          <div class="form-group col-12">
            <label for="nationalityQualifier"
              >{{ t('child/nationalityQualifier') }}* <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i
            ></label>
            <ng-select
              id="nationalityQualifier"
              formControlName="nationalityQualifier"
              [items]="nationalitiesQualifier"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/select')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <div class="d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="gdprConsent" formControlName="gdprConsent" />
                <label class="form-check-label" for="gdprConsent">{{ t('child/gdprConsent') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md col-12 mt-md-0 mt-4">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <app-text-input [childForm]="childForm" formControlName="idCode" [label]="t('child/idCode')"></app-text-input>
          </div>
          <div class="form-group col-12">
            <app-text-input
              [childForm]="childForm"
              formControlName="birthNumber"
              [label]="t('child/birthNumber') + '*'"
              [parentVisible]="true"
              [customValidation]="true"
              customErrorName="invalidBirthNumber"
              [formControlCustomError]="t('child/birthNumberError')"
              placeholder="Např. 210427/0318"
              (valueChange)="onbirthNumberChange($event)"
            ></app-text-input>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="birthDate"
              [label]="t('child/birthDate')"
              [placeholder]="t('child/selectBirthDate')"
              [required]="true"
              [requiredColor]="true"
              [parentVisible]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [formControlError]="t('child/birthDateError')"
            ></app-date-input>
          </div>
          <div class="form-group col-12">
            <label for="gender"
              >{{ t('child/gender') }}<span class="required-color">*</span>&nbsp;<i
                [class]="icons.eye"
                placement="top"
                [ngbTooltip]="t('child/parentInfo')"
              ></i
            ></label>
            <div class="d-flex">
              <div class="form-check">
                <input class="form-check-input" type="radio" id="genderFemale" [value]="GenderEnum.Female" formControlName="gender" />
                <label class="form-check-label label-border-left" for="genderFemale">
                  <img
                    src="assets/img/iconsDefault/{{
                      childForm.get('gender').value === GenderEnum.Female ? 'girlColored' : 'girlNoColor'
                    }}.svg"
                    alt="girl"
                  />
                  {{ t('child/girl') }}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="genderMale" [value]="GenderEnum.Male" formControlName="gender" />
                <label class="form-check-label label-border-right" for="genderMale">
                  <img
                    src="assets/img/iconsDefault/{{ childForm.get('gender').value === GenderEnum.Male ? 'boyColored' : 'boyNoColor' }}.svg"
                    alt="boy"
                  />
                  {{ t('child/boy') }}
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <label for="insuranceCompany"
              >{{ t('child/healthInsurance') }} <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i
            ></label>
            <ng-select
              id="insuranceCompany"
              formControlName="insuranceCompany"
              [items]="insuranceCompany"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectInsuranceCompany')"
              class="form-control"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <app-text-input
          [childForm]="childForm"
          formControlName="importantNote"
          [label]="t('child/importantNote')"
          [parentVisible]="true"
          [placeholder]="t('child/importantNotePlaceholder')"
        ></app-text-input>
      </div>
    </div>

    <hr />

    <div formArrayName="addresses">
      <div class="row no-gutters" *ngFor="let address of addresses.controls; let i = index" [formGroupName]="i">
        <div class="col-12">
          <h2 *ngIf="address.value.addressType === AddressType.Permanent">
            {{ t('child/permanentAddress') }} <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i>
          </h2>
          <h2 class="mt-6" *ngIf="address.value.addressType === AddressType.Temporary">
            {{ t('child/temporaryAddress') }} <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i>
          </h2>
          <h2 class="mt-6" *ngIf="address.value.addressType === AddressType.Contact">
            {{ t('child/contactAddress') }} <i [class]="icons.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i>
          </h2>
        </div>
        <div class="col-md col-12">
          <div class="row no-gutters">
            <div class="form-group col-12">
              <label for="streetAndHouseNumber">
                {{ t('child/streetAndHouseNumber') }}
              </label>
              <input id="streetAndHouseNumber" formControlName="streetAndHouseNumber" class="form-control form-control-lg" type="text" />
            </div>
            <div *ngIf="address.value.addressType === AddressType.Permanent" class="form-group col-12">
              <label for="district"
                >{{ t('child/district') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent">*</ng-container></label
              >
              <input id="district" formControlName="district" class="form-control form-control-lg" type="text" />
            </div>
            <div class="form-group col-12">
              <label for="country">{{ t('child/country') }}*</label>
              <ng-select
                id="country"
                formControlName="country"
                [items]="nationalities"
                bindLabel="text"
                bindValue="value"
                [placeholder]="t('child/selectCountry')"
                class="form-control"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md col-12 mt-md-0 mt-4">
          <div class="row no-gutters">
            <div class="form-group col-12">
              <label for="city"
                >{{ t('child/city') }}<ng-container *ngIf="address.value.addressType === AddressType.Permanent">*</ng-container></label
              >
              <input id="city" formControlName="city" class="form-control form-control-lg" type="text" />
            </div>
            <div class="form-group col-12">
              <label for="postalCode">{{ t('child/postalCode') }}</label>
              <input id="postalCode" formControlName="postalCode" class="form-control form-control-lg" type="text" />
            </div>
            <div *ngIf="address.value.addressType === AddressType.Permanent" class="form-group col-12">
              <div class="d-flex margin-b-5">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="catchment" formControlName="catchment" />
                  <label class="form-check-label" for="catchment">{{ t('child/withinSchoolDistrict') }}*</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-12 mt-6">
          <div class="row no-gutters">
            <div class="form-group col d-flex flex-md-row flex-column">
              <button
                type="button"
                (click)="handleAddresses(AddressType.Temporary, showTemporaryAddress)"
                class="btn btn-outline-primary btn-icon me-md-4 me-0 mb-md-0 mb-4"
              >
                <i [class]="showTemporaryAddress ? icons.plus : icons.minus"></i>
                {{ t('child/addIntermediateAddress', { type: showTemporaryAddress ? t('child/add') : t('child/remove') }) }}
              </button>

              <button
                type="button"
                (click)="handleAddresses(AddressType.Contact, showContactAddress)"
                class="btn btn-outline-primary btn-icon"
              >
                <i [class]="showContactAddress ? icons.plus : icons.minus"></i>
                {{ t('child/addContactAddress', { type: showContactAddress ? t('child/add') : t('child/remove') }) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row no-gutters">
      <div class="col-12">
        <h2>{{ t('child/education') }}</h2>
      </div>
      <div class="col-md col-12">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="attendanceStart"
              [label]="t('child/attendanceStart') + '*'"
              [placeholder]="t('child/selectBirthDate')"
            ></app-date-input>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="attendanceEnd"
              [label]="t('child/attendanceEnd')"
              [placeholder]="t('child/selectBirthDate')"
            ></app-date-input>
          </div>
          <div class="form-group col-12">
            <label for="attendanceFulfillmentMethod">{{ t('child/attendanceFulfillmentMethod') }}*</label>
            <ng-select
              id="attendanceFulfillmentMethod"
              formControlName="attendanceFulfillmentMethod"
              [items]="attendanceFulfillmentMethod"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectAttendanceMethod')"
              class="form-control"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-md col-12 mt-md-0 mt-4">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <label for="attendanceStartCode">{{ t('child/attendanceStartCode') }}*</label>
            <ng-select
              id="attendanceStartCode"
              formControlName="attendanceStartCode"
              [items]="attendanceStartCode"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectCode')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label for="attendanceEndCode">{{ t('child/attendanceEndCode') }}</label>
            <ng-select
              id="attendanceEndCode"
              formControlName="attendanceEndCode"
              [items]="attendanceEndCode"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectCode')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label for="educationCourseChoice">{{ t('child/educationCourseChoice') }}*</label>
            <ng-select
              id="educationCourseChoice"
              formControlName="educationCourseChoice"
              [items]="educationCourseChoice"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectEducationCourseChoice')"
              class="form-control"
            >
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row no-gutters">
      <div class="col-12">
        <h2>{{ t('child/educationalNeed') }}</h2>
      </div>
      <div class="col-md col-12">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <label for="socialCategoryDisadvantage">{{ t('child/socialCategoryDisadvantage') }}*</label>
            <ng-select
              id="socialCategoryDisadvantage"
              formControlName="socialCategoryDisadvantage"
              [items]="socialCategoryDisadvantage"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/socialCategoryDisadvantage')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12 disadvantages">
            <label for="disadvantages">{{ t('child/disadvantages') }}*</label>
            <ng-select
              id="disadvantages"
              formControlName="disadvantages"
              [items]="disadvantages"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectDisadvantage')"
              class="form-control"
              [multiple]="true"
              [maxSelectedItems]="5"
              [clearable]="true"
              [ngClass]="{
                'is-invalid':
                  childForm.get('disadvantages').invalid && (childForm.get('disadvantages').dirty || childForm.get('disadvantages').touched)
              }"
            >
            </ng-select>
            <div
              *ngIf="
                childForm.get('disadvantages').invalid && (childForm.get('disadvantages').dirty || childForm.get('disadvantages').touched)
              "
              class="invalid-feedback"
            >
              <div *ngIf="childForm.get('disadvantages').hasError('invalidDisadvantages')">{{ t('child/disadvantagesError') }}</div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="d-flex margin-b-5">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="ignoranceOfLanguage" formControlName="ignoranceOfLanguage" />
                <label class="form-check-label" for="ignoranceOfLanguage">{{ t('child/ignoranceOfLanguage') }}*</label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <label for="disabilityType">{{ t('child/disabilityType') }}</label>
            <ng-select
              id="disabilityType"
              formControlName="disabilityType"
              [items]="disabilityType"
              [placeholder]="t('child/select')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <app-text-input [childForm]="childForm" formControlName="izoSpz" [label]="t('child/isoNumber')"></app-text-input>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="spzRecommendationDue"
              [label]="t('child/spzRecommendationDue')"
              [placeholder]="t('shared/selectDate')"
            ></app-date-input>
          </div>
          <div class="form-group col-12">
            <label for="aidPurchaseForm">{{ t('child/aidPurchaseForm') }}</label>
            <ng-select
              id="aidPurchaseForm"
              formControlName="aidPurchaseForm"
              [items]="aidPurchaseForm"
              [placeholder]="t('child/select')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="supportingMeasuresStart"
              [label]="t('child/supportingMeasuresStart')"
              [placeholder]="t('shared/selectDate')"
            ></app-date-input>
          </div>
        </div>
      </div>
      <div class="col-md col-12 mt-md-0 mt-4">
        <div class="row no-gutters">
          <div class="form-group col-12">
            <div class="d-flex margin-b-5">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="isHealthDisadvantaged" formControlName="isHealthDisadvantaged" />
                <label class="form-check-label" for="isHealthDisadvantaged">{{ t('child/healthDisadvantaged') }}*</label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <label for="measureLevel">{{ t('child/measureLevel') }}</label>
            <ng-select
              id="measureLevel"
              formControlName="measureLevel"
              [items]="measureLevel"
              bindLabel="text"
              bindValue="value"
              [placeholder]="t('child/selectGrade')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label for="levelOfTalent">{{ t('child/levelOfTalent') }}*</label>
            <ng-select
              id="levelOfTalent"
              formControlName="levelOfTalent"
              [items]="levelOfTalent"
              [placeholder]="t('child/select')"
              class="form-control"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <div class="d-flex mb-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="hasHeavyDisability" formControlName="hasHeavyDisability" />
                <label class="form-check-label" for="hasHeavyDisability">{{ t('child/hasHeavyDisability') }}</label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="spzRecommendationIssued"
              [label]="t('child/spzRecommendationIssued')"
              [placeholder]="t('shared/selectDate')"
            ></app-date-input>
          </div>
          <div class="form-group col-12">
            <label for="supportingMeasures">
              {{ t('child/supportingMeasures') }}
            </label>
            <ng-select
              *ngIf="supportingMeasuresList$ | async as supportingMeasuresList"
              id="supportingMeasures"
              formControlName="supportingMeasures"
              [items]="supportingMeasuresList"
              bindLabel="longName"
              bindValue="id"
              [placeholder]="t('child/select')"
              class="form-control"
              [virtualScroll]="true"
            >
            </ng-select>
          </div>
          <div class="form-group col-12">
            <div class="d-flex margin-b-5">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="isFundingRequired" formControlName="isFundingRequired" />
                <label class="form-check-label" for="isFundingRequired">{{ t('child/isFundingRequired') }}</label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <app-date-input
              [childForm]="childForm"
              formControlName="supportingMeasuresEnd"
              [label]="t('child/supportingMeasuresEnd')"
              [placeholder]="t('shared/selectDate')"
            ></app-date-input>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="row no-gutters">
      <div *ngIf="showWarning" class="col-12 mb-5">
        <div class="alert alert-warning" role="alert">
          <p>{{ t('child/warnToExport') }}</p>
          <p>{{ t('child/warnToCreate') }}</p>
        </div>
      </div>
      <div class="col text-center">
        <button type="button" [routerLink]="links.children()" class="btn btn-lg btn-outline-primary me-4">
          {{ t('child/cancel') }}
        </button>
        <button class="btn btn-lg btn-primary" [disabled]="childForm.invalid">
          {{ submitButtonText }}
        </button>
      </div>
    </div>
  </form>
</div>
