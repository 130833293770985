export * from './announcements.service';
import { AnnouncementsService } from './announcements.service';
export * from './attendance.service';
import { AttendanceService } from './attendance.service';
export * from './children.service';
import { ChildrenService } from './children.service';
export * from './childrenGroup.service';
import { ChildrenGroupService } from './childrenGroup.service';
export * from './cities.service';
import { CitiesService } from './cities.service';
export * from './classes.service';
import { ClassesService } from './classes.service';
export * from './closeRelatives.service';
import { CloseRelativesService } from './closeRelatives.service';
export * from './dayOffs.service';
import { DayOffsService } from './dayOffs.service';
export * from './districts.service';
import { DistrictsService } from './districts.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './eEnrollment.service';
import { EEnrollmentService } from './eEnrollment.service';
export * from './exportXml.service';
import { ExportXmlService } from './exportXml.service';
export * from './gradeBook.service';
import { GradeBookService } from './gradeBook.service';
export * from './invitations.service';
import { InvitationsService } from './invitations.service';
export * from './kindergarten.service';
import { KindergartenService } from './kindergarten.service';
export * from './supportingMeasures.service';
import { SupportingMeasuresService } from './supportingMeasures.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AnnouncementsService, AttendanceService, ChildrenService, ChildrenGroupService, CitiesService, ClassesService, CloseRelativesService, DayOffsService, DistrictsService, DocumentsService, EEnrollmentService, ExportXmlService, GradeBookService, InvitationsService, KindergartenService, SupportingMeasuresService, UsersService];
