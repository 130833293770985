import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import {
  AttendanceEndCodeEnum,
  AttendanceFulfillmentMethodEnum,
  AttendanceStartCodeEnum,
  ChildProfileDetail,
  EducationCourseChoiceEnum,
  InsuranceCompanyEnum,
  LevelOfTalentEnum,
  SupportingMeasuresService,
} from '@isophi/mng-api';
import { map, Observable } from 'rxjs';

import { HelperService } from '../../../../../../core/services/helper.service';
import {
  attendanceEndCodeData,
  attendanceFulfillmentMethodData,
  attendanceStartCodeData,
  educationCourseChoiceData,
  insuranceCompanyData,
  measureLevelData,
  socialCategoryDisadvantageData,
} from '../../../../components/child-form/data';

@Component({
  selector: 'app-child-info-education-tab',
  templateUrl: './child-info-education-tab.component.html',
  styleUrls: ['./child-info-education-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoEducationTabComponent implements OnInit {
  @Input()
  child: ChildProfileDetail;

  attendanceStartCode: { [key: string]: string }[] = [];

  attendanceFulfillmentMethod: { [key: string]: string }[] = [];

  attendanceEndCode: { [key: string]: string }[] = [];

  educationCourseChoice: { [key: string]: string }[] = [];

  insuranceCompany: { [key: string]: string }[] = [];

  socialCategoryDisadvantage = socialCategoryDisadvantageData;

  measureLevel = measureLevelData;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  LevelOfTalentEnum = LevelOfTalentEnum;

  supportingMeasures$: Observable<string>;

  constructor(
    protected iconsService: IconService,
    private helperService: HelperService,
    private supportingMeasuresService: SupportingMeasuresService
  ) {}

  ngOnInit(): void {
    this.attendanceStartCode = this.helperService.prepareData(attendanceStartCodeData, AttendanceStartCodeEnum);
    this.attendanceFulfillmentMethod = this.helperService.prepareData(attendanceFulfillmentMethodData, AttendanceFulfillmentMethodEnum);
    this.attendanceEndCode = this.helperService.prepareData(attendanceEndCodeData, AttendanceEndCodeEnum);
    this.educationCourseChoice = this.helperService.prepareData(educationCourseChoiceData, EducationCourseChoiceEnum);
    this.insuranceCompany = this.helperService.prepareData(insuranceCompanyData, InsuranceCompanyEnum);
    this.supportingMeasures$ = this.supportingMeasuresService
      .supportingMeasuresRetrieve(`${this.child?.supportingMeasures}`)
      .pipe(map((val) => val.longName));
  }
}
