/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KindergartenEnrollmentRegistrationData { 
    applicationCollectionStart: string;
    applicationCollectionEnd: string;
    catchmentArea: string;
    specialClasses?: string;
    note?: string;
    separateWorkplaces?: string;
    acceptTerms: boolean;
    hasPermissions: boolean;
}