import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IconService } from '@isophi/core-ui';
import { AddressTypeEnum, ChildrenService, NationalityEnum, RelativeTypeEnum, Status3f1Enum } from '@isophi/mng-api';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { HelperService } from '../../../../core/services/helper.service';
import { closeRelativeStatusData, closeRelativeTypeData, nationalityData } from '../../components/child-form/data';

@Component({
  selector: 'app-close-relative-form',
  templateUrl: './close-relative-form.component.html',
  styleUrls: ['./close-relative-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseRelativeFormComponent implements AfterViewInit {
  @Input() submitButtonText: string;

  @Input() simplePrimaryBtn = false;

  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  closeRelativeStatusData: { [key: string]: string }[] = [];

  closeRelativeType: { [key: string]: string }[] = [];

  nationalities: { [key: string]: string }[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AddressType = AddressTypeEnum;

  showTemporaryAddress = true;

  showContactAddress = true;

  disabledAddContactAddress = false;

  initialValues;

  get addresses(): FormArray {
    return this.form.get('addresses') as FormArray;
  }

  get minDate(): NgbDateStruct {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear() - 100,
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
  }

  get maxDate(): NgbDateStruct {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
  }

  private _childId: string;

  private _subs: Subscription[] = [];

  constructor(
    private childrenService: ChildrenService,
    private fb: FormBuilder,
    private helperService: HelperService,
    public links: RouterLinkFactory,
    private router: Router,
    private route: ActivatedRoute,
    protected icons: IconService,
    private cd: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      relativeType: [null, Validators.required],
      status: [null, Validators.required],
      birthDate: [null],
      phone: ['+420'],
      email: [null],
      dataBox: [null, Validators.max(6)],
      isPrimary: [false],
      addresses: this.fb.array([
        this.fb.group({
          addressType: [AddressTypeEnum.Permanent],
          sameAsChild: [false],
          streetAndHouseNumber: [''],
          city: [''],
          country: [null],
          district: [''],
          postalCode: [''],
        }),
      ]),
      bankName: [],
      bankAccountNumber: [],
      iban: [],
      swift: [],
      note: [],
    });

    this.initialValues = { ...this.form.value };

    this.closeRelativeStatusData = this.helperService.prepareData(closeRelativeStatusData, Status3f1Enum);
    this.closeRelativeType = this.helperService.prepareData(closeRelativeTypeData, RelativeTypeEnum);
    this.nationalities = this.helperService.prepareData(nationalityData, NationalityEnum);
  }

  ngAfterViewInit(): void {
    this._subs.push(
      this.route.params.subscribe((params) => {
        this._childId = params.id;

        this._subs.push(
          this.childrenService.childrenRetrieve(this._childId).subscribe((child) => {
            this.helperService.smallTextTitle$.next(child.firstName + ' ' + child.lastName);
          })
        );
      })
    );

    this._handleSameAsChildChanges();
  }

  createOrUpdateCloseRelative(navigateBack?: boolean): void {
    if (this.form.valid) {
      const data = this.form.value;
      const bankAccountNumberAndCode = this.form.controls.bankAccountNumber.value;
      if (bankAccountNumberAndCode) {
        data.bankAccountNumber = bankAccountNumberAndCode.split('/')[0];
        data.bankCode = bankAccountNumberAndCode.split('/')[1];
      }
      data.birthDate = this.helperService.formateDate(data.birthDate);
      data.childUuid = this._childId;
      if (data.phone === '+420') {
        data.phone = null;
      }

      this.formSubmit.emit({ data: data, navigateBack: navigateBack });
    }
  }

  navigateBack(): void {
    this.router.navigate(this.links.childrenDetail(this._childId));
  }

  handleAddresses(addressType: AddressTypeEnum, show: boolean) {
    if (show) {
      this.addAddress(addressType);
    } else {
      this.deleteAddress(addressType);
    }

    if (addressType === this.AddressType.Contact) {
      this.showContactAddress = !this.showContactAddress;
    }
    if (addressType === this.AddressType.Temporary) {
      this.showTemporaryAddress = !this.showTemporaryAddress;
    }
  }

  addAddress(addressType: AddressTypeEnum) {
    const addressFormGroup = this.fb.group({
      addressType: [addressType],
      catchment: [false],
      streetAndHouseNumber: [''],
      city: [''],
      country: [null],
      district: [''],
      postalCode: [''],
    });
    this.addresses.push(addressFormGroup);
  }

  deleteAddress(addressType: AddressTypeEnum) {
    for (let i = 0; i < this.addresses.length; i++) {
      const addressFormGroup = this.addresses.at(i) as FormGroup;
      const addressTypeControl = addressFormGroup.get('addressType');

      if (addressTypeControl.value === addressType) {
        this.addresses.removeAt(i);
        break;
      }
    }
  }

  resetCloseRelativeForm(): void {
    this.form.reset(this.initialValues);
    this.cd.markForCheck();
  }

  setFormData(data): void {
    const { addresses, ...dataToEdit } = data;

    const permanentAddress = addresses.find((address) => address.addressType === AddressTypeEnum.Permanent);
    const otherAddress = addresses.filter((address) => address.addressType !== AddressTypeEnum.Permanent);

    otherAddress.forEach((addressData) => {
      this.handleAddresses(addressData.addressType, true);
    });
    this.addresses.patchValue([permanentAddress, ...otherAddress]);
    this.form.patchValue(dataToEdit);
    if (dataToEdit.bankAccountNumber && dataToEdit.bankCode) {
      this.form.controls.bankAccountNumber.setValue(dataToEdit.bankAccountNumber + '/' + dataToEdit.bankCode);
    }
    this.form.controls.birthDate.setValue(this.helperService.parseDate(dataToEdit.birthDate));
    this.cd.markForCheck();
  }

  private _handleSameAsChildChanges() {
    const addresses = this.form.get('addresses') as FormArray;

    addresses.controls.forEach((addressGroup: FormGroup) => {
      if (addressGroup.get('addressType').value !== AddressTypeEnum.Permanent) return;
      const sameAsChildControl = addressGroup.get('sameAsChild');
      this._subs.push(
        sameAsChildControl.valueChanges.subscribe((checked: boolean) => {
          Object.keys(addressGroup.controls).forEach((key) => {
            const control = addressGroup.get(key);
            if (key !== 'sameAsChild' && key !== 'addressType') {
              if (checked) {
                control.disable({ emitEvent: false });
                this.disabledAddContactAddress = true;
              } else {
                control.enable({ emitEvent: false });
                this.disabledAddContactAddress = false;
              }

              this.cd.markForCheck();
            }
          });
        })
      );
    });
  }
}
