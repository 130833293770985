import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ChildrenComponent } from './children.component';
import { ChildrenRoutingModule } from './children-routing.module';
import { ChildFormComponent } from './components/child-form/child-form.component';
import { ChildRowComponent } from './components/child-row/child-row.component';
import { ChildrenFilterComponent } from './components/children-filter/children-filter.component';
import { CloseRelativeFormComponent } from './components/close-relative-form/close-relative-form.component';
import { ChildActualAttendanceComponent } from './pages/child-detail/child-actual-attendance/child-actual-attendance.component';
import { ChildAttendanceRowComponent } from './pages/child-detail/child-actual-attendance/child-attendance-row/child-attendance-row.component';
import { ChildCloseRelativeComponent } from './pages/child-detail/child-close-relative/child-close-relative.component';
import { ChildCloseRelativeRowComponent } from './pages/child-detail/child-close-relative/child-close-relative-row/child-close-relative-row.component';
import { ChildDetailComponent } from './pages/child-detail/child-detail.component';
import { ChildDetailHeaderComponent } from './pages/child-detail/child-detail-header/child-detail-header.component';
import { ChildDetailedInfoComponent } from './pages/child-detail/child-detailed-info/child-detailed-info.component';
import { ChildInfoBasicTabComponent } from './pages/child-detail/child-detailed-info/child-info-basic-tab/child-info-basic-tab.component';
import { ChildInfoDiagnosticTabComponent } from './pages/child-detail/child-detailed-info/child-info-diagnostic-tab/child-info-diagnostic-tab.component';
import { ChildInfoDocumentsTabComponent } from './pages/child-detail/child-detailed-info/child-info-documents-tab/child-info-documents-tab.component';
import { ChildInfoEducationTabComponent } from './pages/child-detail/child-detailed-info/child-info-education-tab/child-info-education-tab.component';
import { CloseRelativeSidebarComponent } from './pages/child-detail/close-relative-sidebar/close-relative-sidebar.component';
import { CreateChildCloseRelativeComponent } from './pages/child-detail/create-close-relative/create-close-relative.component';
import { EditCloseRelativeComponent } from './pages/child-detail/edit-close-relative/edit-close-relative.component';
import { CreateChildComponent } from './pages/create-child/create-child.component';
import { EditChildComponent } from './pages/edit-child/edit-child.component';

@NgModule({
  declarations: [
    ChildrenComponent,
    ChildrenFilterComponent,
    ChildRowComponent,
    ChildDetailComponent,
    CreateChildComponent,
    ChildDetailHeaderComponent,
    ChildActualAttendanceComponent,
    ChildCloseRelativeComponent,
    ChildDetailedInfoComponent,
    ChildAttendanceRowComponent,
    ChildCloseRelativeRowComponent,
    ChildInfoBasicTabComponent,
    ChildInfoEducationTabComponent,
    ChildInfoDiagnosticTabComponent,
    ChildInfoDocumentsTabComponent,
    ChildFormComponent,
    EditChildComponent,
    CreateChildCloseRelativeComponent,
    CloseRelativeSidebarComponent,
    EditCloseRelativeComponent,
    CloseRelativeFormComponent,
  ],
  imports: [CommonModule, SharedModule, ChildrenRoutingModule],
})
export class ChildrenModule {}
