/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `full_day_education_in_kindergarten` - Celodenní vzdělávání v MŠ * `boarding_education_in_kindergarten` - Internátní vzdělávání v MŠ * `half_day_education_in_kindergarten` - Polodenní vzdělávání v MŠ * `education_in_ps_pt` - Vzdělávání v PS/PT * `education_in_kindergarten_ps_pt_terminated` - Vzdělávání v MŠ/PT/PS ukončeno
 */
export type EducationCourseChoiceEnum = 'full_day_education_in_kindergarten' | 'boarding_education_in_kindergarten' | 'half_day_education_in_kindergarten' | 'education_in_ps_pt' | 'education_in_kindergarten_ps_pt_terminated';

export const EducationCourseChoiceEnum = {
    FullDayEducationInKindergarten: 'full_day_education_in_kindergarten' as EducationCourseChoiceEnum,
    BoardingEducationInKindergarten: 'boarding_education_in_kindergarten' as EducationCourseChoiceEnum,
    HalfDayEducationInKindergarten: 'half_day_education_in_kindergarten' as EducationCourseChoiceEnum,
    EducationInPsPt: 'education_in_ps_pt' as EducationCourseChoiceEnum,
    EducationInKindergartenPsPtTerminated: 'education_in_kindergarten_ps_pt_terminated' as EducationCourseChoiceEnum
};