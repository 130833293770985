<ng-container *transloco="let t">
  <div class="app-sidebar__overlay" (click)="sidebarClose()"></div>
  <div class="sticky">
    <aside class="app-sidebar">
      <div class="app-sidebar__logo" data-cy-test="sidebar__logo">
        <a class="header-brand" [routerLink]="links.homepage()">
          <img src="assets/img/logo/iSophiMng-color.svg" class="header-brand-img desktop-lgo" alt="iSophi management brand" />
          <img src="assets/img/logo/iSophiMng-color.svg" class="header-brand-img dark-logo" alt="iSophi management brand" />
          <img src="assets/img/logo/Mng.png" class="header-brand-img mobile-logo" alt="iSophi management brand" />
          <img src="assets/img/logo/Mng.png" class="header-brand-img darkmobile-logo" alt="iSophi management brand" />
        </a>
      </div>
      <div class="app-sidebar3">
        <div class="main-menu h-100">
          <ul class="side-menu">
            <li *ngFor="let item of menuItems" class="slide">
              <a
                class="side-menu__item"
                [attr.data-bs-toggle]="item.subMenu?.length > 0 && 'slide'"
                [routerLink]="item.url"
                routerLinkActive="active"
              >
                <i [class]="item.icon + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ item.menuText }}</span>
                <i *ngIf="item.subMenu?.length > 0" [class]="icons.chevronDown + ' angle'"></i>
              </a>
              <ul class="slide-menu" *ngIf="item.subMenu?.length > 0">
                <li *ngFor="let subMenuItem of item.subMenu">
                  <a class="slide-item" [routerLink]="subMenuItem.url" routerLinkActive="active">
                    {{ subMenuItem.menuText }}
                  </a>
                </li>
              </ul>
            </li>
            <hr />

            <li class="slide" *ngIf="isManager">
              <a class="side-menu__item" [routerLink]="links.userList()" routerLinkActive="active">
                <i [class]="icons.users + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ t('user/title') }}</span>
              </a>
            </li>

            <li class="slide">
              <a class="side-menu__item" [attr.data-bs-toggle]="'slide'" [routerLink]="links.holidaysDates()[0]" routerLinkActive="active">
                <i [class]="icons.settings + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ t('settings/title') }}</span>
                <i [class]="icons.chevronDown + ' angle'"></i>
              </a>
              <ul class="slide-menu">
                <li>
                  <a class="slide-item" [routerLink]="links.holidaysDates()" routerLinkActive="active">{{ t('settings/daysOff') }}</a>
                </li>
                <li *ngIf="!isProduction">
                  <a class="slide-item" [routerLink]="links.importExport()" routerLinkActive="active">
                    {{ t('settings/title') }}
                  </a>
                </li>
                <li>
                  <a class="slide-item" [routerLink]="links.kindergartenData()" routerLinkActive="active">{{
                    t('settings/kindergartenData')
                  }}</a>
                </li>
              </ul>
            </li>

            <li class="slide enrollment" *ngIf="isManager && (enrollmentState$ | async) !== undefined">
              <a class="side-menu__item" [routerLink]="links.enrollment()" routerLinkActive="active">
                <div>
                  <!-- <i [class]="icons.enrollment + ' sidemenu_icon'"></i> -->
                  <img src="/assets/img/pen-tool.svg" alt="enrollment" class="me-4" width="20px" />
                  <span class="side-menu__label">{{ t('enrollment/title') }}</span>
                </div>
                <i *ngIf="(helperService.state$ | async) === null" [class]="icons.star"></i>
              </a>
            </li>
          </ul>

          <ul class="side-menu mb-6">
            <li>
              <a
                class="btn btn-outline-primary btn-sm mb-3"
                [href]="teacherAppUrl"
                target="diagnostika"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.TEACHER)"
                (mouseleave)="changeImage('assets/img/logo/isophi-diag_wheel_logo_256.png', ExternalApps.TEACHER)"
              >
                <img class="sidemenu_icon" [src]="imageSrcTeacher" alt="Teacher logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/diagnostics') }}</span>
              </a>
            </li>
            <li>
              <a
                class="btn btn-outline-primary btn-sm pap-btn mb-3"
                [href]="papAppUrl"
                target="pap"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.PAP)"
                (mouseleave)="changeImage('assets/img/logo/isophi-pap_wheel_logo_256.png', ExternalApps.PAP)"
              >
                <img class="sidemenu_icon" [src]="imageSrcPap" alt="PAP logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/teacherSupport') }}</span>
              </a>
            </li>
            <li>
              <a
                class="btn btn-outline-primary btn-sm parent-btn"
                (click)="openPreviewProductsModal(ExternalApps.PARENT)"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.PARENT)"
                (mouseleave)="changeImage('assets/img/logo/isophi-parent_wheel_logo_256.png', ExternalApps.PARENT)"
              >
                <img class="sidemenu_icon" [src]="imageSrcParent" alt="Parent logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/parents') }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="main-menu mobile-menu h-100" isophiSidemenuToggle>
          <ul class="side-menu">
            <li *ngFor="let item of menuItems" class="slide">
              <a
                class="side-menu__item"
                [attr.data-bs-toggle]="item.subMenu?.length > 0 && 'slide'"
                [routerLink]="item.url"
                routerLinkActive="active"
                (click)="item.subMenu?.length > 0 && handleClick($event)"
              >
                <i [class]="item.icon + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ item.menuText }}</span>
                <i *ngIf="item.subMenu?.length > 0" [class]="icons.chevronDown + ' angle'"></i>
              </a>
              <ul class="slide-menu" *ngIf="item.subMenu?.length > 0">
                <li *ngFor="let subMenuItem of item.subMenu">
                  <a class="slide-item" [routerLink]="subMenuItem.url" routerLinkActive="active">
                    {{ subMenuItem.menuText }}
                  </a>
                </li>
              </ul>
            </li>
            <hr />

            <li class="slide">
              <a class="side-menu__item" [routerLink]="links.userList()" routerLinkActive="active">
                <i [class]="icons.users + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ t('user/title') }}</span>
              </a>
            </li>

            <li class="slide">
              <a
                class="side-menu__item"
                [attr.data-bs-toggle]="'slide'"
                [routerLink]="links.holidaysDates()[0]"
                routerLinkActive="active"
                (click)="handleClick($event)"
              >
                <i [class]="icons.settings + ' sidemenu_icon'"></i>
                <span class="side-menu__label">{{ t('settings/title') }}</span>
                <i [class]="icons.chevronDown + ' angle'"></i>
              </a>
              <ul class="slide-menu">
                <li>
                  <a class="slide-item" [routerLink]="links.holidaysDates()" routerLinkActive="active">{{ t('settings/daysOff') }}</a>
                </li>
                <li *ngIf="!isProduction">
                  <a class="slide-item" [routerLink]="links.importExport()" routerLinkActive="active">
                    {{ t('settings/title') }}
                  </a>
                </li>
                <li>
                  <a class="slide-item" [routerLink]="links.kindergartenData()" routerLinkActive="active">{{
                    t('settings/kindergartenData')
                  }}</a>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="side-menu mb-6">
            <li>
              <a
                class="btn btn-outline-primary btn-sm mb-3"
                [href]="teacherAppUrl"
                target="diagnostika"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.TEACHER)"
                (mouseleave)="changeImage('assets/img/logo/isophi-diag_wheel_logo_256.png', ExternalApps.TEACHER)"
              >
                <img class="sidemenu_icon" [src]="imageSrcTeacher" alt="Teacher logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/diagnostics') }}</span>
              </a>
            </li>
            <li>
              <a
                class="btn btn-outline-primary btn-sm pap-btn mb-3"
                target="pap"
                [href]="papAppUrl"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.PAP)"
                (mouseleave)="changeImage('assets/img/logo/isophi-pap_wheel_logo_256.png', ExternalApps.PAP)"
              >
                <img class="sidemenu_icon" [src]="imageSrcPap" alt="PAP logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/teacherSupport') }}</span>
              </a>
            </li>
            <li>
              <a
                class="btn btn-outline-primary btn-sm parent-btn"
                (click)="openPreviewProductsModal(ExternalApps.PARENT)"
                (mouseover)="changeImage('assets/img/logo/logo.png', ExternalApps.PARENT)"
                (mouseleave)="changeImage('assets/img/logo/isophi-parent_wheel_logo_256.png', ExternalApps.PARENT)"
              >
                <img class="sidemenu_icon" [src]="imageSrcParent" alt="Parent logo" />
                <span class="fw-bold side-menu__label">{{ t('shared/parents') }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  </div>
</ng-container>
