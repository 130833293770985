import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconService } from '@isophi/core-ui';
import { AddressTypeEnum, ChildProfileDetail, LevelOfTalentEnum, NationalityEnum, NationalityQualifierEnum } from '@isophi/mng-api';

import { HelperService } from '../../../../../../core/services/helper.service';
import { nationalityData, nationalityQualifierData } from '../../../../components/child-form/data';

@Component({
  selector: 'app-child-info-basic-tab',
  templateUrl: './child-info-basic-tab.component.html',
  styleUrls: ['./child-info-basic-tab.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInfoBasicTabComponent implements OnInit {
  @Input()
  child: ChildProfileDetail;

  form: FormGroup;

  nationalityQualifier: { [key: string]: string }[] = [];

  nationality: { [key: string]: string }[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  LevelOfTalentEnum = LevelOfTalentEnum;

  get permanentAddress(): string {
    const address = this.child.addresses.find((address) => address.addressType === AddressTypeEnum.Permanent);
    if (address) {
      const addressParts = [address.streetAndHouseNumber, address.postalCode, address.city].filter(
        (part) => part !== null && part !== undefined && part !== ''
      );

      return addressParts.join(' ');
    } else {
      return '';
    }
  }

  get temporaryAddress(): string {
    const address = this.child.addresses.find((address) => address.addressType === AddressTypeEnum.Temporary);
    if (address) {
      const addressParts = [address.streetAndHouseNumber, address.postalCode, address.city].filter(
        (part) => part !== null && part !== undefined && part !== ''
      );

      return addressParts.join(' ');
    } else {
      return '';
    }
  }

  get contactAddress(): string {
    const address = this.child.addresses.find((address) => address.addressType === AddressTypeEnum.Contact);
    if (address) {
      const addressParts = [address.streetAndHouseNumber, address.postalCode, address.city].filter(
        (part) => part !== null && part !== undefined && part !== ''
      );

      return addressParts.join(' ');
    } else {
      return '';
    }
  }

  get catchment(): boolean {
    const address = this.child.addresses.find((address) => address.addressType === AddressTypeEnum.Permanent);
    return address ? address.catchment : false;
  }

  constructor(protected iconsService: IconService, private helperService: HelperService) {}

  ngOnInit(): void {
    this.nationalityQualifier = this.helperService.prepareData(nationalityQualifierData, NationalityQualifierEnum);
    this.nationality = this.helperService.prepareData(nationalityData, NationalityEnum);
  }
}
