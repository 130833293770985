import { OrgTypeContract } from './org-type.contract';

export interface KindergartenContract {
  id: number;
  uuid: string;
  code: string;
  name: string;
  law_id: string;
  note: string;
  product_codenames?: string[];
  org_type?: OrgTypeContract[];
  e_enrollment_state?: boolean;
}
