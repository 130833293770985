// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LoggingLevel } from '@isophi/core-legacy';

import { commonEnvironment } from './environment.common';

export const environment = {
  envShortcut: 'D',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  i18n: true,
  client_id: 'Ny1aiionLm0xuAblsYe9VVBzSB0NrDbnooF08Ter',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SCARF_ANALYTICS: false,
  ga_measurement_id: '',

  api: 'https://api.isophi-merged.doc.endevel.cz/management/api/v1',
  authAPI: 'https://api.isophi-auth.doc.endevel.cz/api/v1',
  teacherAPI: 'https://api.isophi-merged.doc.endevel.cz/teacher/api/v1',
  kindergartenAPI: 'https://api.isophi-merged.doc.endevel.cz/kindergarten/api/v1',
  dadApplicationUrl: 'https://isophi-dad-app.doc.endevel.cz',
  teacherAppUrl: 'https://isophi-teacher-app.doc.endevel.cz',
  papAppUrl: 'https://parent.pap-isophi.doc.endevel.cz/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
