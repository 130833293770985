import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouterLinkFactory {
  /**
   * Navigate application to homepage.
   *
   * Homepage is main page (component), which is rendered after login.
   * It is always safe to redirect to homepage.
   */
  public homepage(): string[] {
    return ['/dashboard'];
  }

  /**
   * Navigate application to login.
   */
  public login(): string[] {
    return ['/login'];
  }

  /**
   * Navigate application to Children list.
   */
  public children(): string[] {
    return ['/children'];
  }

  /**
   * Navigate application to children add form.
   */
  public createChild(): string[] {
    return ['/children', 'new'];
  }

  /**
   * Navigate application to Children detail.
   */
  public childrenDetail(id: string): string[] {
    return ['/children', id];
  }

  /**
   * Navigate application to children edit form.
   */
  public editChild(id: string): string[] {
    return ['/children', id, 'edit'];
  }

  /**
   * Navigate application to children close relative add form.
   */
  public createCloseRelative(id: string): string[] {
    return ['/children', id, 'add-close-relative'];
  }

  /**
   * Navigate application to children edit form.
   */
  public editCloseRelative(id: string, closeRelativeId: string): string[] {
    return ['/children', id, 'edit-close-relative', closeRelativeId];
  }

  /**
   * Navigate application to groups list.
   */
  public groups(): string[] {
    return ['/groups'];
  }

  /**
   * Navigate application to groups detail.
   */
  public groupsDetail(uuid: string): string[] {
    return ['/groups', uuid];
  }

  /**
   * Navigate application to groups add form.
   */
  public createGroup(): string[] {
    return ['/groups', 'new'];
  }

  /**
   * Navigate application to groups edit form.
   */
  public editGroup(uuid: string): string[] {
    return ['/groups', uuid, 'edit'];
  }

  /**
   * Navigate application to today's attendance.
   */
  public attendanceToday(): string[] {
    return ['/attendance', 'today'];
  }

  /**
   * Navigate application to overview's attendance.
   */
  public attendanceOverview(): string[] {
    return ['/attendance', 'overview'];
  }

  /**
   * Navigate application to gradebook list.
   */
  public gradebookList(): string[] {
    return ['/gradebook'];
  }

  /**
   * Navigate application to gradebook add form.
   */
  public createGradebook(): string[] {
    return ['/gradebook', 'new'];
  }

  /**
   * Navigate application to gradebook edit form.
   */
  public editGradebook(uuid: string): string[] {
    return ['/gradebook', uuid, 'edit'];
  }

  /**
   * Navigate application to import-export page.
   */
  public holidaysDates(): string[] {
    return ['/settings', 'holidays-dates'];
  }

  /**
   * Navigate application to holidays dates add form.
   */
  public createHolidaysDates(): string[] {
    return ['/settings', 'holidays-dates', 'new'];
  }

  /**
   * Navigate application to holidays dates edit form.
   */
  public editHolidaysDates(uuid: string): string[] {
    return ['/settings', 'holidays-dates', uuid, 'edit'];
  }

  /**
   * Navigate application to import-export page.
   */
  public importExport(): string[] {
    return ['/settings', 'import-export'];
  }

  /**
   * Navigate application to kindergarten-data page.
   */
  public kindergartenData(): string[] {
    return ['/settings', 'kindergarten-data'];
  }

  /**
   * Navigate application to change password page.
   *
   */
  public changePassword(): string[] {
    return ['/user', 'change-password'];
  }

  /**
   * Navigate application to add user page.
   *
   */
  public addUser(): string[] {
    return ['/user', 'list', 'add-user'];
  }

  /**
   * Navigate application to add user page.
   *
   */
  public editUser(id: string): string[] {
    return ['/user', 'list', 'edit-user', id];
  }

  /**
   * Navigate application to user list page.
   *
   */
  public userList(): string[] {
    return ['/user', 'list'];
  }

  /**
   * Navigate application to gradebook list.
   */
  public announcementList(): string[] {
    return ['/announcement'];
  }

  /**
   * Navigate application to announcement add form.
   */
  public createAnnouncement(): string[] {
    return ['/announcement', 'new'];
  }

  /**
   * Navigate application to announcement edit form.
   */
  public editAnnouncement(id: string): string[] {
    return ['/announcement', id, 'edit'];
  }

  /**
   * Navigate application to enrollment page.
   */
  public enrollment(): string[] {
    return ['/enrollment'];
  }
}
