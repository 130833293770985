<ng-container *transloco="let t">
  <label [for]="formControlName"
    >{{ label }}<ng-container *ngIf="required"><span [class.required-color]="requiredColor">*</span></ng-container
    ><ng-container *ngIf="parentVisible"
      >&nbsp;<i [class]="iconService.eye" placement="top" [ngbTooltip]="t('child/parentInfo')"></i></ng-container
  ></label>
  <div class="input-group">
    <input
      [id]="formControlName"
      class="form-control form-control-lg"
      [placeholder]="placeholder && placeholder"
      [formControl]="getFormControl()"
      [minDate]="minDate"
      [maxDate]="maxDate"
      ngbDatepicker
      #dp="ngbDatepicker"
      type="text"
      container="body"
      [ngClass]="{
        'is-invalid':
          childForm.get(formControlName).invalid && (childForm.get(formControlName).dirty || childForm.get(formControlName).touched)
      }"
    />
    <div class="input-group-append">
      <button class="btn" (click)="dp.toggle()" type="button">
        <img src="assets/img/calendar.svg" alt="calendar" />
      </button>
    </div>
    <ng-container>
      <div
        *ngIf="childForm.get(formControlName).invalid && (childForm.get(formControlName).dirty || childForm.get(formControlName).touched)"
        class="invalid-feedback"
      >
        <div *ngIf="required && childForm.get(formControlName).errors.required">{{ formControlError }}</div>
        <div *ngIf="childForm.get(formControlName).errors.ngbDate?.invalid">{{ t('child/invalidDate') }}</div>
      </div>
    </ng-container>
  </div>
</ng-container>
