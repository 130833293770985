import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthService } from '@isophi/core-legacy';
import { IconService } from '@isophi/core-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { MenuItem } from '../../../core/models/menu.model';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { HelperService } from '../../../core/services/helper.service';
import { KindergartenService } from '../../../core/services/kindergarten.service';
import { PreviewProductsModalComponent } from '../../../shared/components/preview-products-modal/preview-products-modal.component';

enum ExternalApps {
  TEACHER = 'teacher',
  PAP = 'pap',
  PARENT = 'parent',
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  imageSrcTeacher = 'assets/img/logo/isophi-diag_wheel_logo_256.png';

  imageSrcPap = 'assets/img/logo/isophi-pap_wheel_logo_256.png';

  imageSrcParent = 'assets/img/logo/isophi-parent_wheel_logo_256.png';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ExternalApps = ExternalApps;

  enrollmentState$: Observable<boolean | null>;

  private _menuItems: MenuItem[] = [];

  get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  teacherAppUrl: string;

  papAppUrl: string;

  isProduction = environment.production;

  isManager: boolean;

  private readonly kindergartenService = inject(KindergartenService);

  public readonly helperService = inject(HelperService);

  private readonly authService = inject(AuthService);

  public readonly icons = inject(IconService);

  public readonly links = inject(RouterLinkFactory);

  private readonly modal = inject(NgbModal);

  ngOnInit() {
    this.teacherAppUrl = `${environment.teacherAppUrl}/login/external?token=${this.authService.accessToken}`;
    this.papAppUrl = `${environment.papAppUrl}/login/external?token=${this.authService.accessToken}`;
    this._menuItems = this.helperService.menuItems;
    this.isManager = this.authService.loggedUser?.teacher?.isManager();
    this.enrollmentState$ = this.kindergartenService.getEnrollmentState().pipe(
      map((state) => {
        return state.eEnrollmentState;
      }),
      tap((state) => {
        this.helperService.state$.next(state);
      })
    );
    this.loadScript();
  }

  changeImage(newSrc: string, typeOfApp: ExternalApps): void {
    if (this.ExternalApps.TEACHER === typeOfApp) {
      this.imageSrcTeacher = newSrc;
    } else if (this.ExternalApps.PAP === typeOfApp) {
      this.imageSrcPap = newSrc;
    } else if (this.ExternalApps.PARENT === typeOfApp) {
      this.imageSrcParent = newSrc;
    }
  }

  openPreviewProductsModal(typeOfApp: ExternalApps): void {
    const modalRef = this.modal.open(PreviewProductsModalComponent, { size: 'lg', centered: true });

    if (this.ExternalApps.PAP === typeOfApp) {
      modalRef.componentInstance.title = 'Podpora pedagoga od iSophi';
      modalRef.componentInstance.image = 'assets/img/preview/pap_preview.png';
      modalRef.componentInstance.text =
        'Být spokojený v pracovním životě je důležité. Vyvinuli jsme <strong>nástroj přispívající k sebepoznání, rozvoji a pracovní pohodě pedagogů</strong> v mateřských školách. Můžete snadno online poznat své silné stránky i své skryté možnosti. Získáte doporučení pro zvyšování svých kompetencí a kvalifikace.<br /><br /> <strong>Přihlásit se můžete svými iSophi údaji zde: <a href="https://app.podporapedagoga.cz/" target="pap">PodporaPedagoga.cz</a></strong>';
    } else if (this.ExternalApps.PARENT === typeOfApp) {
      modalRef.componentInstance.title = 'Spolupráce s rodinou od iSophi';
      modalRef.componentInstance.image = 'assets/img/preview/parent_preview.png';
      modalRef.componentInstance.text =
        'iSophi pomáhá v komunikaci a usnadňuje zapojení rodičů do vzdělávání. <br /><br /> <strong>Jak pozvat rodiče do aplikace iSophi R?</strong> <ol> <li>Ujistěte se, že je rodič přidán zde v iSophi Management na profilu dítěte jako blízká osoba a že má vyplněný e-mail.</li> <li>Následně můžete na podstránce <a href="' +
        this.links.children() +
        '">„Děti“</a> zaškrtnout všechny, jejichž rodičům má přijít e-mailová pozvánka do rodičovské aplikace.</li> <li>Poté na stejné podstránce v pravém horním rohu po kliknutí z možností vyberete „Pozvat rodiče do aplikace“.</li> <li>Systém ihned rozešle e-mailové pozvánky a zbytek již bude na rodičích.</li> </ol> <strong>Tipy a doplňující informace</strong>: <ul> <li>iSophi Management Vám ukáže, kdo už byl pozván (šedé písmenko „R“) a kdo už pozvánku přijal a do rodičovské aplikace vstoupil (růžové písmenko „R“).</li> <li>Jako bonus v tomto školním roce obdrží rodiče v rámci iSophi R také přístup do modulu pro rozvoj předškoláků, který byl navržený psychology.</li> </ul>';
    }
  }

  loadScript() {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/plugins/sidemenu/sidemenu.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  sidebarClose() {
    document.querySelector('.app')?.classList.remove('sidenav-toggled');
  }

  handleClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }
}
