<div class="children-filter border-r-16" *transloco="let t">
  <div class="left-section">
    <div class="form-group search-child">
      <i [class]="iconService.search + ' input-search-icon'"></i>
      <input [formControl]="nameFilter" type="text" class="form-control" [placeholder]="t('child/searchChild')" />
    </div>
    <div class="form-group group-list" *ngIf="childrenGroups$ | async as childrenGroups">
      <ng-select
        id="groups"
        name="groups"
        class="form-control"
        [items]="childrenGroups"
        [multiple]="false"
        bindLabel="name"
        bindValue="uuid"
        [placeholder]="t('child/class')"
        [(ngModel)]="selectedGroup"
        (change)="filterChildren()"
        appendTo="body"
      >
      </ng-select>
    </div>
    <div class="form-group archive">
      <div class="form-check form-switch mb-0">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="archivedChildrenSwitchCheck"
          [(ngModel)]="archivedChildren"
          (change)="filterChildren()"
        />
        <label class="form-check-label" for="archivedChildrenSwitchCheck">
          {{ t('child/archivedChildren') }}
        </label>
      </div>
    </div>
  </div>

  <div class="right-section">
    <div class="form-group mx-4">
      <ng-select
        class="form-control"
        [placeholder]="t('child/select')"
        [multiple]="false"
        [clearable]="false"
        [formControl]="initChildMenu"
        (change)="onOptionSelected($event)"
        [readonly]="!selectedChildren.length"
        [ngClass]="{ 'disable-select': !selectedChildren.length }"
      >
        <ng-option [value]="ChildMenuEnum.EXPORT">{{ t('child/simpleExport') }}</ng-option>
        <ng-option [value]="ChildMenuEnum.EXPORT_WITH_CONTACT">{{ t('child/tableWithContactsExport') }}</ng-option>
        <ng-option [value]="ChildMenuEnum.INVITE">{{ t('child/inviteParent') }}</ng-option>
        <ng-option [value]="ChildMenuEnum.ARCHIVE">{{ t('shared/archive') }}</ng-option>
        <ng-option [value]="ChildMenuEnum.REMOVE">{{ t('shared/remove') }}</ng-option>
      </ng-select>
    </div>

    <button (click)="createChild()" class="btn btn-primary btn-icon">
      <i [class]="iconService.plus"></i>
      {{ t('child/addChild') }}
    </button>
  </div>

  <ng-template #toastTemplate>
    <p class="mb-0" [innerHTML]="infoMessage"></p>
  </ng-template>

  <ng-template #toastTemplate2>
    <p class="mb-0" [innerHTML]="infoMessage2"></p>
  </ng-template>
</div>
